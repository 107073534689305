import React from "react";
import * as Unicons from "@iconscout/react-unicons";
import "./Card.css";

const Card = ({ product }) => {
  const [isToggle, setIsToggle] = React.useState(false);
  const { id, title, sub_category: category, size } = product;

  function handleToggling() {
    setIsToggle(!isToggle);
  }

  let prop = [];

  for (const key in product) {
    if (["id", "title", "sub_category"].includes(key)) {
      continue;
    }

    let name = key.split("_").join(" ").replace(key[0], key[0].toUpperCase());
    prop.push([name, product[key]]);
  }
  return (
    <>
      <div className="card-body">
        <div className="score">
          Id : {id}
          {/* <span>Vendor: {BRAND_NAME}</span> */}
          {/* {REVENUE && " | "}
        <span
          style={{
            display: REVENUE ? "inline" : "none",
          }}
        >
          Revenue: ₹ {REVENUE}
        </span> */}
        </div>
        <div className="card-title">
          Title : {title.replace(/\[SEP\]/g, "")}
        </div>
        <div className="other">
          Sub category : {category.replace(/\[SEP\]/g, "")}
        </div>
        <div className="other">Size : {size.replace(/\[SEP\]/g, "")}</div>
        {isToggle &&
          prop.map((item) => {
            return (
              <div>
                {item[0]} : {item[1].replace(/\[SEP\]/g, "")}
              </div>
            );
          })}
        {isToggle ? (
          <Unicons.UilAngleUp className="angle" onClick={handleToggling} />
        ) : (
          <Unicons.UilAngleDown className="angle" onClick={handleToggling} />
        )}
      </div>
    </>
  );
};

export default Card;
